import Vue from 'vue'
import Breakpoints from '../vue/breakpoints'
import LazyLoad from "vanilla-lazyload";
import Swiper, { Navigation } from 'swiper';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

Swiper.use([Navigation]);

// Intiate Vuejs instance
// var app = new Vue({
//     el: '#app',
//     delimiters: ["${","}"],
//     components: {
//         Breakpoints,
//     }
// })

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {


    const homeBanner = document.querySelector('.section-large-banner');
    if(homeBanner){
        homeBanner.classList.add('loaded');
    }
    
    const videos = document.querySelectorAll("video.lazyload");
    videos.forEach(video => {
        const sources = video.querySelectorAll("source");
        sources.forEach(source => {
            source.src = source.getAttribute("data-src");
        });
        video.load();
        video.addEventListener('canplay', () => {
            video.play();  // Add autoplay when the video is ready
        });
    });

    // Scrool to anchor link
    let anchorlinks = document.querySelectorAll('a[href^="#"]')
 
    for (let anchor of anchorlinks) { 
        anchor.addEventListener('click', (e)=> {
            let hashval = anchor.getAttribute('href')
            let target = document.querySelector(hashval)
            target.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            })
            history.pushState(null, null, hashval)
            e.preventDefault()
        })
    }

    gsap.set(".img-content-fade", {opacity: 0, y:-10});

    ScrollTrigger.batch(".img-content-fade", {
        onEnter: (elements, triggers) => {
            gsap.to(elements, {opacity: 1, y: 0, stagger: 0.2});
        },
        start: "top center+=320px"
        //markers: true
    });

    // Lazyload
    var lazy = new LazyLoad({
        elements_selector: ".lazy"
    });

    // TOOGLE MOBILE MENU //
    const navTrigger = document.querySelector('.js-open-nav');
    const mobNav = document.querySelector('.mobile-nav');
    const projectWrapper = document.getElementById('app');

    if(navTrigger){ 
        bindEvent(navTrigger, 'click', function() {
            navTrigger.classList.toggle('is-active');
            mobNav.classList.toggle('js-is-open');
            projectWrapper.classList.toggle('js-is-open');
        }, false);
    }

    // SWIPER
    var swiper = new Swiper('.swiper-container', {

        grabCursor: 'true',
        slidesPerView: 1,
        spaceBetween: 30,

        breakpoints: {
            768: {
              spaceBetween: 64,
            },
        },
        
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    // OVERLAY FUNCTIONALITY
    const overlayTriggers = document.querySelectorAll('.js-open-overlay');
    const overlayImage = document.getElementById('overlay-image');
    const close = document.getElementById('js-close-modal');
    const closeImg = document.querySelectorAll('.js-close-modal-img');
    const modal = document.querySelector('.js-modal');
    const app = document.getElementById('app');
    const swiperWrapper = document.querySelectorAll('.swiper-wrapper');

    if(overlayTriggers.length){ 
        for (var i = 0; i < overlayTriggers.length; i++) {
            overlayTriggers[i].addEventListener('click',  function(e) {
                e.stopPropagation()
                overlayImage.classList.add('show');

                const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
                projectWrapper.style.position = 'fixed';
                projectWrapper.style.top = `-${scrollY}`;


                if(swiperWrapper.length){
                    const slideNumber = this.getAttribute('data-position');
                    swiper.slideTo(slideNumber);
                }
            });
        }

        close.addEventListener('click', function(){
            overlayImage.classList.remove("show");

            const scrollY = projectWrapper.style.top;
            projectWrapper.style.position = '';
            projectWrapper.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        });

        //Closes the modal when the document is clicked
        overlayImage.addEventListener('click', () => {
            overlayImage.classList.remove('show');

            const scrollY = projectWrapper.style.top;
            projectWrapper.style.position = '';
            projectWrapper.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        });

        //Prevents the click event from reaching the document
        app.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        modal.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        window.addEventListener('scroll', () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        });
    }


    const overlayTriggersVideo = document.querySelectorAll('.js-open-overlay-home');
    const overlayVideo = document.getElementById('overlay-video-home'); 

    if(overlayTriggersVideo.length){ 
        for (var i = 0; i < overlayTriggersVideo.length; i++) {
            overlayTriggersVideo[i].addEventListener('click',  function(e) {
                e.stopPropagation()
                overlayVideo.classList.add('show');

                const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
                projectWrapper.style.position = 'fixed';
                projectWrapper.style.top = `-${scrollY}`;

            });
        }

        close.addEventListener('click', function(){
            overlayVideo.classList.remove("show");

            const scrollY = projectWrapper.style.top;
            projectWrapper.style.position = '';
            projectWrapper.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        });

        //Closes the modal when the document is clicked
        overlayVideo.addEventListener('click', () => {
            overlayVideo.classList.remove('show');

            const scrollY = projectWrapper.style.top;
            projectWrapper.style.position = '';
            projectWrapper.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        });

        //Prevents the click event from reaching the document
        app.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        modal.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        window.addEventListener('scroll', () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        });
    }

    const overlayTriggersImgContent = document.querySelectorAll('.js-open-overlay-img');
    const overlayImg = document.getElementById("overlay-image-content");
    const overlayAllImg = document.querySelectorAll("#overlay-image-content .js-modal-img");

    if(overlayTriggersImgContent.length){ 
        for (var i = 0; i < overlayTriggersImgContent.length; i++) {
            overlayTriggersImgContent[i].addEventListener('click',  function(e) {

                let overlayItem = this.getAttribute("data-item");
                overlayAllImg.forEach(item => {
                    item.classList.add("hidden");
                    if(item.getAttribute("data-item") == overlayItem) {
                        item.classList.remove("hidden");
                    }
                });



                e.stopPropagation()
                overlayImg.classList.add('show');
                
                const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
                projectWrapper.style.position = 'fixed';
                projectWrapper.style.top = `-${scrollY}`;
            });
        }

        if(closeImg){ 
            closeImg.forEach(item => {
                item.addEventListener('click', function(){
                    overlayAllImg.forEach(overlay => {

                            overlay.classList.add("hidden");

                    });
                    overlayImg.classList.remove("show");
                    
                });
                
            })
        }

        //Closes the modal when the document is clicked
        overlayImg.addEventListener('click', () => {
            overlayImg.classList.remove('show');

            const scrollY = projectWrapper.style.top;
            projectWrapper.style.position = '';
            projectWrapper.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        });

        //Prevents the click event from reaching the document
        app.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        // modal.addEventListener('click', (e) => {
        //     e.stopPropagation();
        // });

        window.addEventListener('scroll', () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        });
    }    

    // Accordions
    // If there is an accordion inside an accordion then data-accordion-allow-multiple needs to be used
    const accordionItems = document.querySelectorAll('[data-accordion-item]');

    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionLink = accordion.firstElementChild;
            bindEvent(accordionLink, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {
        const link = this.closest('[data-accordion-link]');
        const parent = link.closest('[data-accordion]');
        const content = parent.querySelectorAll('[data-accordion-content]');

        content.forEach(function(content) {
            if (content.previousElementSibling === link) {
                content.classList.toggle('is-active');
                link.classList.toggle('is-active');
                link.setAttribute('aria-expanded', link.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }
            else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('is-active');
                    content.previousElementSibling.classList.remove('is-active');
                    content.previousElementSibling.setAttribute('aria-expanded', 'false');
                }
            }
        });
    }

    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

})
